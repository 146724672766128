<template>
    <v-navigation-drawer
        v-model='drawerOpen'
        class='elevation-1'
        left>
            <v-list nav>
                <template v-for='item in modifiedItems'>
                    <v-list-item
                        v-if='item.type === "single"'
                        :key='item.name'
                        :prepend-icon='item.icon'
                        :value='item.path'
                        @click='routeTo(item.path)'
                    >
                        <div>
                            {{item.name}}
                        </div>
                    </v-list-item>
                    <v-list-group
                        :value='item.path'
                        v-else-if='item.type === "multi"'
                        :key='item.icon'
                        :prepend-icon='item.icon'
                    >
                        <template v-slot:activator='{ props }'>
                            <v-list-item
                            v-bind='props'
                            >
                                <div>
                                    {{item.name}}
                                </div>
                            </v-list-item>
                        </template>

                        <v-list-item
                            v-for='child in item.children'
                            :key='child.name'
                            :prepend-icon='child.icon'
                            :value='child.path'
                            @click='routeTo(child.path)'
                            style='padding-left: 30px !important'
                        >
                            <div>
                                {{child.name}}
                            </div>
                        </v-list-item>
                    </v-list-group>
                </template>
        </v-list>
        <template v-slot:append>
            <div class='pa-2'>
                <v-btn block color='primary' @click='doLogout'>
                Logout
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import ActionRoles from '@/helpers/actionRoles';
import { SOLUTIONS } from '@/helpers/dataLabels';
import {COMPARE_NAMES_KEY, USE_BATCH} from '@/helpers/localStorageLabels';
import { rolesObject } from '@/helpers/roles';

export default {
    props: {
        isDrawerOpen: {
            type: Boolean,
        },
    },
    data() {
        return {
            adminRoles: ActionRoles.adminRoles,
            drawerOpen: false,
            userSolutions: localStorage.getItem('solutions'),
            items: [
                {
                    name: 'Home Page',
                    icon: 'mdi-home',
                    path: '/',
                    type: 'single',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            userRole: 'userRole',
            userId: 'userId',
        }),
        isAuthenticated(){
            return this.$store.getters.isAuthenticated;
        },
        useBatch() {
          return JSON.parse(localStorage.getItem(USE_BATCH));
        },
        useCompareNames() {
          return JSON.parse(localStorage.getItem(COMPARE_NAMES_KEY));
        },

        modifiedItems() {
            if (ActionRoles.sysAdmin.includes(this.userRole)){
              const tempArr = this.items.concat(
                  {
                    name: 'Settings',
                    icon: 'mdi-account-plus-outline',
                    type: 'multi',
                    children: [
                      {
                        name: 'User Management',
                        icon: 'mdi-account-multiple-plus',
                        path: '/admin/user-management',
                        type: 'single',
                      },
                      {
                        name: 'User Audit',
                        icon: 'mdi-account-multiple-plus',
                        path: '/admin/user-audit',
                        type: 'single',
                      },
                      {
                        name: 'Tenants',
                        icon: 'mdi-bank',
                        path: '/admin/tenant-management',
                        type: 'single',
                      },
                      {
                        name: 'Tenants Audit',
                        icon: 'mdi-bank',
                        path: '/admin/tenant-audit',
                        type: 'single',
                      },                      
                    ],
                  });
              if(!this.$store.getters.readOnly){
                tempArr.push({
                  name: 'Admin Control',
                  icon: 'mdi-security',
                  path: '/admin/system-control',
                  type: 'single',
                });
              }
              return tempArr;                  
            }
            else if (ActionRoles.adminRoles.includes(this.userRole)) {
                const tempArr = this.items.filter(item => item.path !== '/ach-upload' && item.path !== '/tf360-upload' && item.path !== '/tf799-upload'); 
                return tempArr.concat(
                    {
                      name: 'Settings',
                      icon: 'mdi-account-plus-outline',
                      type: 'multi',
                      children: [
                        {
                          name: 'User Management',
                          icon: 'mdi-account-multiple-plus',
                          path: '/admin/user-management',
                          type: 'single',
                        },
                        {
                          name: 'User Audit',
                          icon: 'mdi-account-multiple-plus',
                          path: '/admin/user-audit',
                          type: 'single',
                        },
                        {
                          name: 'System configurations',
                          icon: 'mdi-cog',
                          path: '/admin/system-configuration',
                          type: 'single',
                        },
                      ],
                    },
                );                
            }
            if (ActionRoles.chiefRoles.includes(this.userRole)) {
                return this.items.concat({
                    name: 'Team Structure',
                    icon: 'mdi-account-plus-outline',
                    path: '/supervision/users',
                    type: 'single',
                });
            }
           
            return this.items;
        },
    },
    watch: {
        isDrawerOpen(value) {
            this.drawerOpen = value;
        },
        drawerOpen(value) {
            this.$emit('updateToggle', value); 
        },
    },

    created() {
      this.addToMenu();
    },

    methods: {
        ...mapActions({ logout: AUTH_LOGOUT }),
        isUserSystemAdmin() {
            return this.userRole === 'SYS_ADMIN';
        },
        isUserAdmin() {
            return this.userRole === 'ADMIN';
        },
        isUserChief(){
          return this.userRole === 'CHIEF_SUPERVISOR';
        },        
        isUserBsa() {
          return this.userRole === 'BSA';
        },
        routeTo(page) {
            const currentPath = this.$router.currentRoute.value.name;
            if (currentPath !== page) {
                this.$router.push({ path: page });
            }
        },
        async doLogout() {
          await this.logout({token: '', isForce: false, messageForLogout: 'Manual logout'});
          this.$router.go({ name: 'login' });
        },
        addToMenu() {
          if (this.useBatch && !this.isUserBsa()) {
              this.items.splice(1, 0, 
                {
                    name: 'Batches',
                    icon: 'mdi-file-compare',
                    type: 'multi',
                    children: [
                        {
                            name: 'Create Batch',
                            icon: 'mdi-file-document-arrow-right-outline',
                            path: '/batches/new',
                            type: 'single',
                        },
                        {
                            name: 'Batches List',
                            icon: 'mdi-file-multiple',
                            path: '/batches',
                            type: 'single',
                        },
                    ],
                },
              );
            }
          // Querylog is for CHIEF, ADMIN, SYS_ADMIN
          if (this.isUserSystemAdmin() || this.isUserAdmin() || this.isUserChief() || this.isUserBsa()) {                
            const querylog = 1; 
            this.items.splice(querylog,0,{
                  name: 'Audit Trail',
                  icon: 'mdi-format-list-bulleted',
                  type: 'multi',
                  children: [],
                });      
            if (this.userSolutions?.includes(SOLUTIONS.wire)) {
              this.items[querylog].children.push(
                {
                    name: 'Wire',
                    icon: 'mdi-format-list-bulleted',
                    path: '/query-log',
                    type: 'single',
                });
            }    
            if (this.userSolutions?.includes(SOLUTIONS.aml)) {
              this.items[querylog].children.push(
                {
                    name: 'Manual Search',
                    icon: 'mdi-format-list-bulleted',
                    path: '/aml/query-log',
                    type: 'single',
                });
            }       
            // if (this.userSolutions?.includes(SOLUTIONS.ach)){
            //   this.items[querylog].children.push(
            //     {
            //       name: 'Ach',
            //       icon: 'mdi-format-list-bulleted',
            //       path: '/ach/query-log',
            //       type: 'single',
            //     });
            // }            
            // if (this.userSolutions?.includes(SOLUTIONS.pkyc)){
            //   this.items[querylog].children.push(
            //     {
            //       name: 'PKYC',
            //       icon: 'mdi-format-list-bulleted',
            //       path: '/pkyc/query-log',
            //       type: 'single',
            //     });
            // }      
            if (this.userSolutions?.includes(SOLUTIONS.tf360)){
              this.items[querylog].children.push(
                  {
                    name: 'Trade Finance 360',
                    icon: 'mdi-format-list-bulleted',
                    path: '/tf/360-log',
                    type: 'single',
                  },
              );       
            }    
            if (this.userSolutions?.includes(SOLUTIONS.tf799)){
              this.items[querylog].children.push(
                  {
                    name: 'Trade Finance 799',
                    icon: 'mdi-format-list-bulleted',
                    path: '/tf/799-log',
                    type: 'single',
                  },
              );       
            }    
            if (this.userSolutions?.includes(SOLUTIONS.tf360) || this.userSolutions?.includes(SOLUTIONS.tf799)){
              this.items[querylog].children.push(                  
                  {
                    name: 'Trade Finance Searches',
                    icon: 'mdi-format-list-bulleted',
                    path: '/tf/manual-log',
                    type: 'single',
                  },
              );       
            }
          }

          if (this.useCompareNames) {
            this.items.splice(1, 0, {
              name: 'Compare Names',
              icon: 'mdi-compare-horizontal',
              path: '/compare-names',
              type: 'single',
            });
          }  
          // For both ADMIN and SYS_ADMIN
          if (this.isUserSystemAdmin() || this.isUserAdmin()) {     
            if (this.userSolutions?.includes(SOLUTIONS.pkyc)) {
              this.items.push({
                name: 'PKYC files',
                icon: 'mdi-file-upload-outline',
                path: '/pkyc-upload',
                type: 'single',
              });
            }
            // Data sources
            const dataSources = this.items.push({
                  name: 'Data sources',
                  icon: 'mdi-database',
                  type: 'multi',
                  children: [],
                },
            ) - 1;
            this.items[dataSources].children.push(
                {
                  name: 'Custom lists',
                  icon: 'mdi-table-of-contents',
                  path: '/admin/custom-sources',
                  type: 'single',
            });
            
            if(this.isUserSystemAdmin()) {
              this.items[dataSources].children.push(
                  {
                    name: 'Official Lists',
                    icon: 'mdi-table-of-contents',
                    path: '/admin/summary-reports',
                    type: 'single',
              });
            }
            if(this.isUserAdmin()) {
              this.items[dataSources].children.push(
                  {
                    name: 'Official Lists', 
                    icon: 'mdi-table-of-contents',
                    path: '/admin/available-sources',
                    type: 'single',
              });
            }

            if(this.isUserSystemAdmin() || this.isUserAdmin()) {
              this.items[dataSources].children.push(
                  {
                    name: 'SDN Updates', 
                    icon: 'mdi-table-of-contents',
                    path: '/admin/sdn-updates',
                    type: 'single',
              });
            }

            this.items[dataSources].children.push(
              {
                name: 'Accounts for 314',
                icon: 'mdi-table-of-contents',
                path: '/admin/custom-accounts',
                type: 'single',
            });
            
            const persistence = this.items.push({
                  name: 'Persistence',
                  icon: 'mdi-file-compare',
                  type: 'multi',
                  children: [],
              },
            ) - 1;
            this.items[persistence].children.push(
                {
                  name: 'Wire',
                  icon: 'mdi-table-of-contents',
                  path: '/admin/persistence',
                  type: 'single',
                },
            );
            if(this.isUserSystemAdmin() || this.userSolutions?.includes(SOLUTIONS.pkyc)) {
              this.items[persistence].children.push({
                name: 'pKYC',
                icon: 'mdi-table-of-contents',
                path: '/admin/pkyc-persistence',
                type: 'single',
              });
            }          
            this.items[persistence].children.push({
              name: 'ACH',
              icon: 'mdi-table-of-contents',
              path: '/admin/ach-persistence',
              type: 'single',
            });
            
            // FOR SYS ADMIN
            if (this.isUserSystemAdmin()) {
              const reports = this.items.push({
                    name: 'Reports',
                    icon: 'mdi-list-box-outline',
                    type: 'multi',
                    children: [],
                  },
              ) - 1;
              this.items[reports].children.push(
                  {
                    name: 'Daily report',
                    icon: 'mdi-table-of-contents',
                    path: '/reports/daily',
                    type: 'single',
                  },
                  {
                    name: 'Activity report',
                    icon: 'mdi-table-of-contents',
                    path: '/reports/activity',
                    type: 'single',
                  },
                  {
                    name: 'Solutions Reports',
                    icon: 'mdi-table-of-contents',
                    path: '/reports',
                    type: 'single',
                  },
              );
              
              // Sources in-use
              this.items[dataSources].children.push(
                  {
                    name: 'Sources in-use',
                    icon: 'mdi-table-of-contents',
                    path: '/admin/data-sources',
                    type: 'single',
                  },
              );
            }
          }    
          // AML SEARCH
          if (this.userSolutions?.includes(SOLUTIONS.aml) && !this.isUserBsa()) {
            this.items.splice(1, 0, {
              name: 'Manual Search',
              icon: 'mdi-magnify',
              path: '/manual-search',
              type: 'single',
            });
          }
          
          if (this.userSolutions?.includes(SOLUTIONS.tf799) && !this.isUserBsa()) {
              if (!this.isUserAdmin()) {
                this.items.splice(4, 0, {
                    name: 'Upload Trade Finance 799 File',
                    icon: 'mdi-file-upload-outline',
                    path: '/tf799-upload',
                    type: 'single',
                });                
              }
          }
          if (this.userSolutions?.includes(SOLUTIONS.tf360) && !this.isUserBsa()) {
              if (!this.isUserAdmin()) {
                this.items.splice(4, 0, {
                    name: 'Upload Trade Finance 360 File',
                    icon: 'mdi-file-upload-outline',
                    path: '/tf360-upload',
                    type: 'single',
                });                
              }
          }
          if ((this.userSolutions?.includes(SOLUTIONS.tf360) || this.userSolutions?.includes(SOLUTIONS.tf799)) && !this.isUserBsa()) {
            this.items.splice(3, 0, {
                  name: 'Trade Finance Search',
                  icon: 'mdi-magnify',
                  path: '/tf-search',
                  type: 'single',
              });
          }

          if (ActionRoles.actionRoles.includes(this.userRole)) {
            let caseMgmtIndex = this.items.push(
                {
                  name: 'Case Mgmt.',
                  icon: 'mdi-file-document',
                  type: 'multi',
                  children: [],
                },
            ) - 1;
            if (this.userSolutions?.includes(SOLUTIONS.wire)) {
              this.items[caseMgmtIndex].children.push(
                  {
                    name: 'Wire Queries',
                    icon: 'mdi-table-of-contents',
                    path: '/supervision/wire/tables',
                    type: 'single',
                  },
                  {
                    name: 'Wire Dashboard',
                    icon: 'mdi-view-dashboard',
                    path: '/supervision/wire/dashboard',
                    type: 'single',
                  },
              );
            }
            if (this.userSolutions?.includes(SOLUTIONS.tf360) ) {
              this.items[caseMgmtIndex].children.push(
                  {
                    name: 'Trade Finance 360 Queries',
                    icon: 'mdi-table-of-contents',
                    path: '/supervision/tf360/tables',
                    type: 'single',
                  },
                  {
                    name: 'Trade Finance 360 Dashboard',
                    icon: 'mdi-view-dashboard',
                    path: '/supervision/tf360/dashboard',
                    type: 'single',
                  },
              );
            }
            if (this.userSolutions?.includes(SOLUTIONS.tf799) ) {
              this.items[caseMgmtIndex].children.push(
                  {
                    name: 'Trade Finance 799 Queries',
                    icon: 'mdi-table-of-contents',
                    path: '/supervision/tf799/tables',
                    type: 'single',
                  },
                  {
                    name: 'Trade Finance 799 Dashboard',
                    icon: 'mdi-view-dashboard',
                    path: '/supervision/tf799/dashboard',
                    type: 'single',
                  },
              );
            }
            if (this.userSolutions?.includes(SOLUTIONS.pkyc)) {
              this.items[caseMgmtIndex].children.push(
                  {
                    name: 'PKYC Queries',
                    icon: 'mdi-table-of-contents',
                    path: '/supervision/pkyc/tables',
                    type: 'single',
                  },
              );
              this.userRole !== rolesObject.ANALYST && this.items[caseMgmtIndex].children.push(
                {
                    name: 'PKYC Dashboard',
                    icon: 'mdi-view-dashboard',
                    path: '/supervision/pkyc/dashboard',
                    type: 'single',
                  },
              );
            }
            if (this.userSolutions?.includes(SOLUTIONS.ach)) {
              this.items.splice(3,0,  {
                    name: 'Upload ACH File',
                    icon: 'mdi-file-upload-outline',
                    path: '/ach-upload',
                    type: 'single',
                });
              if (this.items[caseMgmtIndex].name !== 'Case Mgmt.') {
                caseMgmtIndex = this.items.findIndex(item => item.name === 'Case Mgmt.');
              }
              this.items[caseMgmtIndex].children.push(
                  {
                    name: 'ACH Files',
                    icon: 'mdi-table-of-contents',
                    path: '/supervision/ach/tables',
                    type: 'single',
                  },
                  {
                    name: 'ACH Dashboard',
                    icon: 'mdi-view-dashboard',
                    path: '/supervision/ach/dashboard',
                    type: 'single',
                  },
              );
            }
            if (this.userSolutions?.includes(SOLUTIONS.aml)) {
              this.items[caseMgmtIndex].children.push(
                  {
                    name: 'Manual Search Queries',
                    icon: 'mdi-table-of-contents',
                    path: '/supervision/aml/tables',
                    type: 'single',
                  },
              );
            }
          }
          if (this.isUserBsa()) {
            const dataSources = this.items.push({
                  name: 'Data sources',
                  icon: 'mdi-database',
                  type: 'multi',
                  children: [],
                },
            ) - 1;
            this.items[dataSources].children.push(
                  {
                    name: 'SDN Updates', 
                    icon: 'mdi-table-of-contents',
                    path: '/admin/sdn-updates',
                    type: 'single',
              });
            
            this.items[dataSources].children.push(
                {
                  name: 'Official Lists',
                  icon: 'mdi-table-of-contents',
                  path: '/admin/summary-reports',
                  type: 'single',
            });

            const persistence = this.items.push({
                  name: 'Persistence',
                  icon: 'mdi-file-compare',
                  type: 'multi',
                  children: [],
              },
            ) - 1;
            this.items[persistence].children.push(
                {
                  name: 'Wire',
                  icon: 'mdi-table-of-contents',
                  path: '/admin/persistence',
                  type: 'single',
                },
            );
            if(this.userSolutions?.includes(SOLUTIONS.pkyc)) {
              this.items[persistence].children.push({
                name: 'pKYC',
                icon: 'mdi-table-of-contents',
                path: '/admin/pkyc-persistence',
                type: 'single',
              });
            }          
            this.items[persistence].children.push({
              name: 'ACH',
              icon: 'mdi-table-of-contents',
              path: '/admin/ach-persistence',
              type: 'single',
            });
            
          }
        },
    },
};
</script>

<style>
    .v-list-item--active {
        background: #161a4f !important;
        color: #fff !important;
    }

    .v-list-group--open > div:first-child {
      background-color: #dedede !important;
      color: black !important;
    }
</style>
