import { useWebSocket } from '@vueuse/core';
import { useToast } from 'vue-toast-notification';
import { reactive } from 'vue';
import { USER_NAME_KEY, USER_TOKEN_KEY } from '@/helpers/localStorageLabels';

export const useWebSocketHandler = () => {
    const $toast = useToast();
    const progress = reactive({});

    const hostForWEbsocket = window.location.host.includes('localhost') ? 'ws://localhost:4567/api/v1/ws/' : `wss://${window.location.host}/api/v1/ws/`;
    
    // console.log(`Connecting to WebSocket at ${hostForWEbsocket}`);
    
    const { send, status, open, close } = useWebSocket(hostForWEbsocket, {
        onConnected: () => {
            console.log('WebSocket connected');
        },
        onDisconnected: () => {
            console.log('WebSocket disconnected');
        },
        onError: (wsInstance, event) => {
            console.error('WebSocket error:', {
                message: event.message || 'Unknown error',
                type: event.type,
                target: event.target,
                timeStamp: event.timeStamp,
            });
        },
        onMessage: (_, event) => {
            const messageData = event.data;

            if (messageData.includes('logout')) {
                const { message } = JSON.parse(messageData);
                $toast.error(message || 'You\'ve been logged out');

                setTimeout(() => {
                    $toast.clear();
                    window.location.reload();
                }, 2500);
                return;
            } else if (messageData.includes('Update on batch progress')) {
                const parsedMessageData = JSON.parse(messageData);
                const { batchId, progress: batchProgress } = parsedMessageData;

                if (batchId && batchProgress !== undefined) {
                    // making sure we'll update the progress only to go up
                    if (!progress[batchId] || batchProgress > progress[batchId]) {                     
                        progress[batchId] = batchProgress; // Update the reactive object
                    } 
                }
            }

            const token = localStorage.getItem(USER_TOKEN_KEY);
            const username = localStorage.getItem(USER_NAME_KEY);
            if (token) {
                send(`custom-pong, token >>>${token}<<< user${username};`);
            } else {
                return;
            }
        },
    });

    return { progress, status, open, close };
};
