/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { pullAllWith } from 'lodash/array';
import apiClient from '@/services/apiClient';
import ongoingClient from '@/services/ongoingClient';

import { authorize } from '@/store/helpers/auth';
import {
  REPORT_DASHBOARD_STATISTICS_DATA,
  QUERY_LOG_DELETE_REQUEST,
  QUERY_LOG_DELETE_ERROR,
  QUERY_LOG_DELETE_SUCCESS,
  QUERY_LOG_ERROR,
  QUERY_LOG_REQUEST,
  QUERY_LOG_INIT,
  QUERY_LOG_SUCCESS,
  QUERY_LOG_RESET_REQUEST,
  QUERY_LOG_RESET_SUCCESS,
  QUERY_LOG_RESET_ERROR,
  QUERY_LOG_REFRESH_REQUEST,
  QUERY_LOG_REFRESH_SUCCESS,
  QUERY_LOG_REFRESH_ERROR,
  QUERY_LOG_UPDATE_ERROR,
  QUERY_LOG_UPDATE_SUCCESS,
  QUERY_LOG_UPDATE_REQUEST,
  QUERY_LOG_FETCH_ITEM_REQUEST,
  QUERY_LOG_SAVE_TABLE,
  QUERY_LOG_FETCH_ITEM_SUCCESS,
  QUERY_LOG_FETCH_ITEM_ERROR,
  QUERY_LOG_SET_DETAILS_ID,
} from '@/store/actions/queryLog';
import { Labels } from '@/helpers/dataLabels.js';
import {USER_ROLE_KEY} from '@/helpers/localStorageLabels';
import {get} from 'lodash';

const state = {
  reportDashboardQueries: [],
  result: [],
  status: '',
  currTable: [],
  noMore: false,
  hasLoadedOnce: false,
  userRole: localStorage.getItem(USER_ROLE_KEY),
  analystDashboardStatuses: [ Labels.REOPENED, Labels.PENDING ],
  supervisorDashboardStatuses: [ Labels.OPEN, Labels.REOPENED, Labels.PENDING, Labels.SUBMITTED ],
  detailsId: null,
};

const getters = {
  reportDashboardQueries: (state) => state.reportDashboardQueries,
  analystGetQueriesStatuses: (state) => state.analystDashboardStatuses,
  supervisorGetQueriesStatuses: (state) => state.supervisorDashboardStatuses,
  result: (state) => state.result,
  isLoading: (state) => state.status === 'loading',
  hasLoadedOnce: (state) => state.hasLoadedOnce,
  noMore: (state) => state.noMore,
  status: (state) => state.status,
  userRole: (state) => state.userRole,
  currTable: (state) => state.currTable,
  detailsId: (state) => state.detailsId,
};

const actions = {
  [QUERY_LOG_SET_DETAILS_ID]: ({commit}, payload) => {
    commit(QUERY_LOG_SET_DETAILS_ID, payload);
  },
  [REPORT_DASHBOARD_STATISTICS_DATA]: ({commit}, payload) => {
    commit(REPORT_DASHBOARD_STATISTICS_DATA , payload);
    return `Update Dashboard reports with ${payload.length} queries statistics`;
  },
  [QUERY_LOG_DELETE_REQUEST]: ({ commit, dispatch }, items) => {
    commit(QUERY_LOG_DELETE_REQUEST);
    const ids = items.map((item) => item.id);
    return ongoingClient
      .delete(ids)
      .then(() => {
        commit(QUERY_LOG_DELETE_SUCCESS, ids);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(QUERY_LOG_DELETE_ERROR, err);
      });
  },
  [QUERY_LOG_RESET_REQUEST]: ({ commit, dispatch }, items) => {
    commit(QUERY_LOG_RESET_REQUEST);
    const ids = items.map((item) => item.id);
    return ongoingClient
      .reset(ids)
      .then((data) => {
        commit(QUERY_LOG_RESET_SUCCESS, data);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(QUERY_LOG_RESET_ERROR, err);
      });
  },
  [QUERY_LOG_REFRESH_REQUEST]: ({ commit, dispatch }, items) => {
    commit(QUERY_LOG_REFRESH_REQUEST);
    const ids = items.map((item) => item.id);
    return ongoingClient
      .refresh(ids)
      .then((data) => {
        commit(QUERY_LOG_REFRESH_SUCCESS, data);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(QUERY_LOG_REFRESH_ERROR, err);
      });
  },

  [QUERY_LOG_UPDATE_REQUEST]: ({ commit, dispatch }, { items, status }) => {
    commit(QUERY_LOG_UPDATE_REQUEST);
    const ids = items.map((item) => item.id);
    return apiClient
      .updateStatus(ids, status)
      .then((data) => {
        data.data = data.data.resultData;
        commit(QUERY_LOG_UPDATE_SUCCESS, data);
        // dispatch(QUERY_LOG_REQUEST, {tenantId, query: null});
        return {status: 'success' , data: 'Status was updated'};
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(QUERY_LOG_UPDATE_ERROR, err);
        return {status: 'Error', data: 'Error - status was not updated'};

      });
  },

      [QUERY_LOG_REQUEST]: ({ commit, dispatch }, {tenantId, query}) => {
        commit(QUERY_LOG_REQUEST);
        return apiClient
      .getQueryLog(tenantId, query)
      .then(({ data }) => {
        commit(QUERY_LOG_SUCCESS, data);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(QUERY_LOG_ERROR, err);
      });
      },

  [QUERY_LOG_SAVE_TABLE]: ({ commit }, items) => {
    commit(QUERY_LOG_SAVE_TABLE, items);
  },

  [QUERY_LOG_INIT]: ({ commit }) => {
    commit(QUERY_LOG_INIT);
  },

  [QUERY_LOG_FETCH_ITEM_REQUEST]: ({ commit, dispatch }, item) => {
    commit(QUERY_LOG_FETCH_ITEM_REQUEST);
    return apiClient
      .getQueryLogDetails({_id: item._id})
      .then((response) => {
        response.data.resultData = get(response, 'data.resultData[0]', {});
        commit(QUERY_LOG_FETCH_ITEM_SUCCESS, response);
      })
      .catch((err) => {
        authorize(err, dispatch);
        commit(QUERY_LOG_FETCH_ITEM_ERROR, err);
      });
  },
};

const reportsDashboardUpddateMutation = (state, payload) => {
  state.reportDashboardQueries = payload;
};

const loadingMutation = (state) => {
  state.status = 'loading';
};

const errorMutation = (state) => {
  state.status = 'error';
  state.hasLoadedOnce = true;
};
const updateResult = (state, { data }) => {
  let dataToProcess = data;
  if (!Array.isArray(dataToProcess)) {
    dataToProcess = [dataToProcess];
  }

  state.status = 'success';
  // eslint-disable-next-line no-underscore-dangle
  pullAllWith(state.result, dataToProcess, (a, b) => a.id === b._id);
  // eslint-disable-next-line no-underscore-dangle
  const toAdd = dataToProcess.map((item) => ({ ...item, id: item._id || item.id }));
  state.result = state.result.filter((result) => {
    const checkers = toAdd.filter((item) => {
      return item.id != result.id;
    });
    return checkers.length;
  });
  state.result = [...toAdd, ...state.result];
};

const mutations = {
  [QUERY_LOG_SET_DETAILS_ID]: (state, id) => {
    state.detailsId = id;
  },
  [REPORT_DASHBOARD_STATISTICS_DATA]: reportsDashboardUpddateMutation,
  [QUERY_LOG_DELETE_REQUEST]: loadingMutation,
  [QUERY_LOG_DELETE_SUCCESS]: (state, ids) => {
    state.status = 'success';
    if (ids?.length) {
      state.result = state.result.filter((item) => !ids.includes(item.id));
    }
  },

  [QUERY_LOG_SAVE_TABLE]: (state, items) => {
    state.currTable = items;
  },

  [QUERY_LOG_RESET_REQUEST]: loadingMutation,
  [QUERY_LOG_RESET_SUCCESS]: updateResult,
  [QUERY_LOG_RESET_ERROR]: errorMutation,

  [QUERY_LOG_REFRESH_REQUEST]: loadingMutation,
  [QUERY_LOG_REFRESH_SUCCESS]: updateResult,
  [QUERY_LOG_REFRESH_ERROR]: errorMutation,

  [QUERY_LOG_UPDATE_REQUEST]: loadingMutation,
  [QUERY_LOG_UPDATE_SUCCESS]: updateResult,
  [QUERY_LOG_UPDATE_ERROR]: errorMutation,

  [QUERY_LOG_FETCH_ITEM_REQUEST]: loadingMutation,
  [QUERY_LOG_FETCH_ITEM_SUCCESS]: updateResult,
  [QUERY_LOG_FETCH_ITEM_ERROR]: errorMutation,

  [QUERY_LOG_REQUEST]: loadingMutation,
  [QUERY_LOG_SUCCESS]: (state, data) => {
    state.status = 'success';
    if (data.length) {
      state.result = data;
    } else {
      state.noMore = true;
    }
    state.hasLoadedOnce = true;
  },
  [QUERY_LOG_ERROR]: errorMutation,

  [QUERY_LOG_INIT]: (state) => {
    state.status = '';
    state.result = [];
    state.noMore = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
