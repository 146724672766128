const ActionRoles = {
  analystRoles: ['ANALYST'],  
  bsaRoles: ['BSA'],
  actionRoles: ['SUPERVISOR', 'ANALYST', 'CHIEF_SUPERVISOR'],
  submittedTables: ['CHIEF_SUPERVISOR', 'SUPERVISOR'],
  adminRoles: ['SYS_ADMIN', 'ADMIN'],
  chiefRoles: ['SYS_ADMIN', 'ADMIN' , 'CHIEF_SUPERVISOR'],  
  roles: ['ADMIN', 'BSA', 'CHIEF_SUPERVISOR', 'SUPERVISOR', 'ANALYST'],
  sysAdmin: ['SYS_ADMIN'],
};

export default ActionRoles;
