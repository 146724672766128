export const QUERYLOGS_FETCH_REQUEST = 'QUERYLOGS_FETCH_REQUEST';
export const QUERYLOGS_FETCH_SUCCESS = 'QUERYLOGS_FETCH_SUCCESS';
export const QUERYLOGS_FETCH_ERROR = 'QUERYLOGS_FETCH_ERROR';

export const READ_LIST = 'READ_LIST';

export const QUERYLOGS_SUPERVISOR_FETCH_REQUEST = 'QUERYLOGS_SUPERVISOR_FETCH_REQUEST';
export const QUERYLOGS_SUPERVISOR_FETCH_SUCCESS = 'QUERYLOGS_SUPERVISOR_FETCH_SUCCESS';
export const QUERYLOGS_SUPERVISOR_FETCH_ERROR = 'QUERYLOGS_SUPERVISOR_FETCH_ERROR';

export const QUERYLOGS_WORKER_FETCH_REQUEST = 'QUERYLOGS_WORKER_FETCH_REQUEST';
export const QUERYLOGS_WORKER_FETCH_SUCCESS = 'QUERYLOGS_WORKER_FETCH_SUCCESS';
export const QUERYLOGS_WORKER_FETCH_ERROR = 'QUERYLOGS_WORKER_FETCH_ERROR';

export const QUERYLOG_UPDATE_REQUEST = 'QUERYLOG_UPDATE_REQUEST';
export const QUERYLOG_UPDATE_SUCCESS = 'QUERYLOG_UPDATE_SUCCESS';
export const QUERYLOG_UPDATE_ERROR = 'QUERYLOG_UPDATE_ERROR';
