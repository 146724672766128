import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import ToastPlugin from 'vue-toast-notification';
import VueCookies from 'vue-cookies';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import 'typeface-roboto/index.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import { ApiClient } from '@/services/apiClient';
import supervisionClient from '@/services/supervisionClient';
import cacheServiceClient from '@/services/cacheServiceClient';
import batchClient from '@/services/batchClient';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { useWebSocketHandler } from '@/utils/websocket';

const plugins = [store, router, vuetify, VueCookies, ToastPlugin];
const unAuth = () => store.dispatch(AUTH_LOGOUT);

const app = createApp(App);
app.component('VueDatePicker', VueDatePicker);
app.config.globalProperties.$apiClient = new ApiClient(unAuth);
app.config.globalProperties.$supervisionClient = supervisionClient;
app.config.globalProperties.$batchClient = batchClient;
app.config.globalProperties.$cacheServiceClient = cacheServiceClient;

plugins.forEach((plugin) => app.use(plugin));

app.config.globalProperties.$showNotification = function(message, type) {
    this.$toast.open({
        position: 'bottom',
        message: message,
        type: type,
    });
};

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then((registration) => {        
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      }).catch((err) => {
        console.log('ServiceWorker registration failed: ', err);
      });
    });
  }

// Initialize WebSocket and add it to global properties
const { progress } = useWebSocketHandler();
app.config.globalProperties.$batchProgress = progress;

app.mount('#app');
