<template>
  <v-footer app class='pa-0'>
    <v-col
      class='text-center caption pa-1 font-weight-thin'
      cols='12'
      style='background: #d9d4d4; cursor: default'
      padless
      >Copyright © 2016-{{ new Date().getFullYear() }} FinCom.co All rights
      reserved.
    </v-col>
  </v-footer>
</template>

<script>
export default {};
</script>
