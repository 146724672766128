export const USER_TOKEN_KEY = 'user-token';
export const USER_ROLE_KEY = 'user-role';
export const USER_READ_ONLY_KEY = 'user-read-only';
export const USER_NAME_KEY = 'user-name';
export const USER_ID_KEY = 'user-id';
export const TENANT_ID_KEY = 'tenantId';
export const DATE_FORMAT_KEY = 'dateFormat';
export const TIMEZONE_KEY = 'timezone';
export const SOLUTIONS_KEY = 'solutions';
export const TIME_FRAME_IN_MINUTES_KEY = 'timeFrameInMinutes';
export const SHOULD_PERSIST_KEY = 'shouldPersist';
export const SESSION_ID_KEY = 'sessionId';
export const CURRENT_QUERY_LOG_ID_KEY = 'currentQuerylogId';
export const TIME_RANGE_KEY = 'timeRange';
export const COMPARE_NAMES_KEY = 'compareNames';
export const SHOW_PKYC = 'showPkyc';
export const PKYC_ENTITIES = 'pkycEntities';
export const USE_BATCH = 'useBatch';
export const SELECT_TEAM_KEY = 'shouldSelectTeam';
export const TEAM_ID_KEY = 'teamId';

export default {
    USER_TOKEN_KEY,
    USER_ROLE_KEY,
    USER_NAME_KEY,
    USER_ID_KEY,
    TENANT_ID_KEY,
    DATE_FORMAT_KEY,
    TIMEZONE_KEY,
    SOLUTIONS_KEY,
    TIME_FRAME_IN_MINUTES_KEY,
    SHOULD_PERSIST_KEY,
    SESSION_ID_KEY,
    CURRENT_QUERY_LOG_ID_KEY,
    TIME_RANGE_KEY,
    COMPARE_NAMES_KEY,
    SHOW_PKYC,
    PKYC_ENTITIES,
    USE_BATCH,
    TEAM_ID_KEY,
};