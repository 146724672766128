<template>
  <div v-if='showAlert' class='rotate-alert'>
    <p>Please rotate your device to landscape mode.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAlert: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkOrientation);
    this.checkOrientation();
  },
  methods: {
    checkOrientation() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      this.showAlert = isMobile && (window.innerWidth < window.innerHeight);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOrientation);
  },
};
</script>

<style scoped>
.rotate-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  z-index: 9999;
}
</style>
