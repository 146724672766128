const SYS_ADMIN = 'SYS_ADMIN';
const ADMIN = 'ADMIN';
const BSA = 'BSA';
const CHIEF_SUPERVISOR = 'CHIEF_SUPERVISOR';
const SUPERVISOR = 'SUPERVISOR';
const ANALYST = 'ANALYST';

export const rolesObject = {
  SYS_ADMIN,
  ADMIN,
  BSA,
  CHIEF_SUPERVISOR,
  SUPERVISOR,
  ANALYST,
};

