export default [
  {
    path: '/batches',
    name: 'batches',
    component: () => import(/* webpackChunkName: "BatchesListsView" */ '../views/batches/BatchesListsView.vue'),
  },
  {
    path: '/batches/new',
    name: 'batches-new',
    component: () => import(/* webpackChunkName: "NewBatchView" */ '../views/batches/NewBatchView.vue'),
  },
];