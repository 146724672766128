import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from '@/store/modules/auth';
import users from '@/store/modules/users';
import supervision from '@/store/modules/supervision';
import queryLog from '@/store/modules/queryLog';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { 
    auth, 
    users, 
    supervision, 
    queryLog, 
  },
  plugins: [createPersistedState()],
});
