<template>
  <v-dialog v-model='dialogMutable' max-width='600px' persistent>
    <v-card>
      <v-card-title class='bg-primary'>{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-col class='text-right'>
          <v-btn v-if='cancelText' @click='closeModal(false)'>{{ cancelText }}</v-btn>
          <v-btn @click='closeModal(true)' color='primary' variant='elevated'>{{ confirmText }}</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    title: String,
    text: String,
    dialog: Boolean,
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
  },
  data() {
    return {
      dialogMutable: false,
    };
  },
  watch: {
    dialog(val) {
      this.dialogMutable = val;
    },
  },
  methods: {
    closeModal(dialogResult) {
      this.dialogMutable = false;
      this.$emit('closeConfirmModal', dialogResult);
    },
  },
};
</script>
